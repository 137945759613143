<div class="input-flex">
  <!--  <p-multiSelect [options]="projectData" [(ngModel)]="selectedProjects" optionLabel="project_name"-->
  <!--    placeholder="請選擇專案"></p-multiSelect>-->
  <ejs-multiselect [(ngModel)]="selectedProjects" [dataSource]="projectData" [placeholder]="'請選擇專案'"
                   [fields]="checkFields" [mode]="'CheckBox'" [popupHeight]="'350px'" [allowCustomValue]="true"
                   [showDropDownIcon]="true" showSelectAll="true" [filterBarPlaceholder]="'搜尋專案'"
                   [selectAllText]="'選擇全部'" [unSelectAllText]="'取消全選'">
  </ejs-multiselect>
  <p-button styleClass="p-button-sm" title="搜尋" icon="pi pi-search" (click)="submitProjects()"></p-button>
  <p-button styleClass="p-button-sm" title="共同編輯" icon="pi pi-pencil" (click)="checkEditer()"
            *ngIf="isEditing && isSingle"></p-button>
  <p-button styleClass="p-button-sm bg-red-300" title="取消編輯" icon="pi pi-sign-out" (click)="canceEditer()"
            *ngIf="isCance"></p-button>
  <div *ngIf="isSingle">

    <p-button [styleClass]="getStatusColor(project_status)" title="" [disabled]="true">{{ project_status }}</p-button>
  </div>
  <p-button *ngIf="isSingle && canEdit" styleClass="p-button-sm" icon="pi pi-save" title="儲存資料"
            (click)="submit()"></p-button>
</div>

<!--<div class="gantt-menu">-->
<!--  <p-toggleButton styleClass="p-button-sm" [(ngModel)]="showBaseline" onLabel="基準線: ON"-->
<!--                  offLabel="基準線: OFF"></p-toggleButton>-->
<!--  <p-button *ngIf="selectedProjects.length < 2" styleClass="p-button-sm" icon="pi pi-save" title="儲存資料"-->
<!--            (click)="submit()"></p-button>-->
<!--</div>-->

<div class="gantt-group">
  <div *ngIf="isLoading" class="loading-screen">
    <span class="loader"></span>
  </div>
  <ejs-gantt #gantt id="gantt"
             [height]="'calc(100vh - (15px + 32px + 53px) - 45px)'"
             [treeColumnIndex]='1' dateFormat="yyyy-MM-dd" [loadingIndicator]="{indicatorType: 'Spinner'}"
             [dataSource]="ganttData" [resources]="resourceData" [columns]="columns"
             [taskFields]="taskSettings" [resourceFields]="resourceFields"
             [addDialogFields]="addDialogFields" [editDialogFields]="editDialogFields"
             [contextMenuItems]="ContextMenuItems" [toolbar]="toolbar"
             [holidays]="holidays" [eventMarkers]="eventMarkers" [workWeek]="workWeek"
             [labelSettings]="labelSettings" [splitterSettings]="splitterSettings"
             [timelineSettings]="timelineSettings"
             [selectionSettings]="selectionSettings"
             [editSettings]="editSettings"
             [renderBaseline]="showBaseline" [showColumnMenu]="true"
             [highlightWeekends]="true" [disableHtmlEncode]="false"
             [enableContextMenu]="selectedProjects.length < 2"
             [allowFiltering]="true" [allowUnscheduledTasks]="true"
             [allowExcelExport]="true" [allowPdfExport]="true"
             [allowReordering]="true" [allowResizing]="true"
             [allowRowDragAndDrop]="allowRowDragAndDrop" [allowSelection]="true"
             (toolbarClick)="toolbarClick($event)"
             (contextMenuClick)="contextMenuClick($event)"
             (rowSelecting)="rowSelecting($event)"
             (queryTaskbarInfo)="queryTaskbarInfo($event)"
             (queryCellInfo)="queryCellInfo($event)"
             (actionComplete)="actionComplete($event)"
             (actionBegin)="actionBegin($event)"
             (beforeTooltipRender)="beforeTooltipRender($event)"
              [collapseAllParentTasks]="true"
              >
    <e-columns >
      <e-column [isPrimaryKey]="true" field='task_id' width='100'>
        <ng-template #template let-data>
          <div class="flex align-items-center h-full">
            <span>{{ data.ganttProperties.taskId }}</span>
            <span (disabled)="isLoading" *ngIf="data.taskData.files" class="pi pi-paperclip ml-2 text-blue-500 opacity-80 cursor-pointer"
            (click)="showUploadDiaolg(data)"></span>
      <span  *ngIf="!data.taskData.files" class="pi pi-paperclip ml-2 opacity-20 cursor-pointer"
            (click)="showUploadDiaolg(data)"></span>
          </div>
        </ng-template>
      </e-column>

     <e-column field='task_name' headerText='名稱' width='250'></e-column>
      <e-column field="notes"  width="100" ></e-column>
      <e-column field='resources' headerText='負責人' width="190"></e-column>
      <e-column field='duration' format='N2' width="100"></e-column>
      <e-column field='start_date' width="150"></e-column>
      <e-column field='end_date' width="150"></e-column>
      <e-column field='baseline_start_date' [allowEditing]='false' width="150"></e-column>
      <e-column field='baseline_end_date' [allowEditing]='false' width="150"></e-column>
      <e-column field='baseline_duration' headerText='基準工作天' [allowEditing]='false'
                [valueAccessor]="this.addUnit" width="50"></e-column>
      <e-column field='progress'></e-column>
      <e-column field='predecessor'></e-column>
      <e-column field='cost' headerText='工時表' editType='numericedit' params='{decimals: 2, value: 5}'></e-column>
      <e-column field='indicatorsName' headerText='標誌名稱' [visible]='false'></e-column>
      <e-column field='indicatorsDate' headerText='標誌日期' [allowEditing]='false' [visible]='false'></e-column>
      <e-column field='indicatorsTooltip' headerText='標誌提示' [visible]='false'></e-column>
      <e-column field='indicatorsClass' headerText='標誌樣式' [visible]='false' editType='dropdownedit'
                [edit]="editParams">
      </e-column>
      <e-column field='indicatorsDelete' headerText='清除標誌' [visible]='false' editType='dropdownedit'></e-column>
      <e-column field='outline_number' headerText='大綱編號' [allowEditing]='false'></e-column>
      <e-column field='task_uuid' [visible]='false' [allowEditing]='false'></e-column>
    </e-columns>
  </ejs-gantt>
</div>
<!--<pre>-->
<!--  {{resources | json}}-->
<!--  {{ganttData | json}}-->
<!--  {{eventMarkers | json}}-->
<!--  </pre>-->
<ejs-dialog #dialog header="專案事件" [showCloseIcon]='true' [allowDragging]='true'
            [isModal]='true' [visible]='false'>
  <ejs-grid #grid [dataSource]='eventMarkers' [toolbar]="eventToolbarItems"
            [editSettings]='eventEditOptions' height='300'
            (actionComplete)="editEvents($event)">
    <e-columns>
      <e-column field='label' headerText='名稱' width='80' textAlign='Center'
                [validationRules]='orderidrules'></e-column>
      <e-column field='day' headerText='日期' width='80' textAlign='Center' editType='datepickeredit'
                [format]="formatoptions"></e-column>
    </e-columns>
  </ejs-grid>
</ejs-dialog>


<!-- ejs-dialog 上傳檔案 -->
<ejs-dialog #uploadDialog header="上傳檔案" [showCloseIcon]='true' [allowDragging]='true'
            [isModal]='true' [visible]='isUpload' (close)="isUpload = false">

  <!-- primeng upload 上傳到後端 -->
  <p-fileUpload mode="advanced" name="demo[]" chooseLabel="選擇檔案上傳" (onSelect)="onUpload($event)"></p-fileUpload>


</ejs-dialog>

<ejs-dialog #upload_dialog header="附件" [showCloseIcon]='true' [isModal]='true' [visible]='false'>
  <p-tabView>
    <p-tabPanel header="列表">
      <ejs-grid #fileGrid [dataSource]='files' height='300'>
        <e-columns>
          <e-column field='file_name' headerText='附件名稱' width='50' textAlign='Center'></e-column>
          <!--          <e-column field='created_at' headerText='上傳日期' width='40' textAlign='Center'>-->
          <!--            <ng-template #template let-data>-->
          <!--              <div class="flex justify-content-center align-items-center h-full">-->
          <!--                <span>{{ data.created_at | date:'yyyy-MM-dd' }}</span>-->
          <!--              </div>-->
          <!--            </ng-template>-->
          <!--          </e-column>-->
          <e-column field='file_size' headerText='檔案大小' width='50' textAlign='Center'>
            <ng-template #template let-data>
              <div class="flex justify-content-center align-items-center h-full">
                <span>{{ formatBytes(data.file_size)  }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column field='file_url' headerText='下載' width='20' textAlign='Center'>
            <ng-template #template let-data>
              <div class="flex justify-content-center align-items-center h-full">
                <p-button icon="pi pi-download"
                          styleClass="w-2rem h-2rem p-button-rounded p-button-secondary p-button-text"
                          (click)="downloadFile(data.id)"></p-button>
              </div>
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
    </p-tabPanel>
    <p-tabPanel *ngIf="isUploading" header="上傳" [disabled]="!canEdit">
      <div class="card flex justify-content-center" *ngIf="Uploading" >
        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
      </div>
      <p-fileUpload #fileUpload [customUpload]="true" [multiple]="true" (uploadHandler)="uploadHandler($event)"
                    [maxFileSize]=512000000 chooseLabel="選擇" uploadLabel="上傳" cancelLabel="清除">
      </p-fileUpload>
    </p-tabPanel>
  </p-tabView>
</ejs-dialog>
