import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
    this.initializeLocalStorage();
  }

  initializeLocalStorage() {
    localStorage.setItem('cache', '');
    for (let i = 1; i < 11; i++) {
      localStorage.setItem(`undoData${i}`, '');
      localStorage.setItem(`redoData${i}`, '');
    }
  }

  saveData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getData(key: string) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  removeData(key: string) {
    localStorage.setItem(key, '');
  }
}
