import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../models/api-response.interface';
import {environment} from '../../environments/environment.development';


interface FileInfo {
  base64: string;
  file_name: string;
  source_uuid: string;
}
const fileHttpOptions = {
  headers: new HttpHeaders({'Content-Type': 'form-data'})
};

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private baseUrl = `${environment.apiUrl}/${environment.projectCode}/${environment.apiVersion}`;
  private fileUrl = `${this.baseUrl}/files`;

  constructor(private http: HttpClient) { }

  uploadFiles(body: any): Observable<ApiResponse> {
    console.log(body)

    return this.http.post<any>(`${this.fileUrl}`,body);
  }

  getTaskFiles(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.fileUrl}/task/${id}`);
  }

  downloadFile(id:string): Observable<ApiResponse>{
    return this.http.get<any>(`${this.fileUrl}/download/${id}`)
  }
  downloads3File(url: string): any {
    return this.http.get(url, { responseType: 'blob'});
  }

  multUpload(body: any): Observable<ApiResponse>{
    return this.http.post<any>(`${this.fileUrl}/all-multipart`,body)
  }

  putUpload(body: any): Observable<ApiResponse>{
    return this.http.post<any>(`${this.fileUrl}/all`,body)
  }

}
