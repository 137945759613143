import {Component, HostListener, OnInit, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {EditSettingsModel as GanttEditSettingsModel, GanttComponent, ColumnModel} from '@syncfusion/ej2-angular-gantt';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {DropDownList} from "@syncfusion/ej2-angular-dropdowns";
import {DialogComponent} from "@syncfusion/ej2-angular-popups";
import {GanttService} from 'src/app/services/gantt.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from "@angular/router";
import {catchError, map, Observable, timeout} from "rxjs";
import {TokenStorageService} from "../../services/token-storage.service";
import {EditSettingsModel as GridEditSettingsModel, GridComponent, ToolbarItems} from "@syncfusion/ej2-angular-grids";
import {Button} from '@syncfusion/ej2-buttons';
import {LocalStorageService} from "../../services/local-storage.service";
import {ChangeDetectorRef} from '@angular/core';
import {FileUpload, FileUploadHandlerEvent} from "primeng/fileupload";
import {FileService} from "../../services/file.service";
import {formatDate} from "@angular/common";
import { S3Client, PutObjectCommand, PutBucketCorsCommand } from "@aws-sdk/client-s3";
import { environment } from 'src/environments/environment';



interface FileInfo {
  base64: string
  file_name: string
  source_uuid: string
}
interface FileObj {
  file_name: string;
  file_path: string;
  file_size: number;
}

interface FileObjInfo {
  source_uuid: string
  files: FileObj[]
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})

export class MainComponent implements OnInit {

  @ViewChild('gantt', {static: true}) ganttObj!: GanttComponent;
  @ViewChild('dialog') dialogObj!: DialogComponent;
  @ViewChild('grid') gridObj!: GridComponent;
  @ViewChild('upload_dialog') uploadDialogObj!: DialogComponent;
  @ViewChild('fileGrid') fileGridObj!: DialogComponent;
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  isUpload: boolean = false
  isCance: boolean = false
  isEditing: boolean = false

  宣告() {
  };

  /* ============================================== 宣告 ============================================== */
  id: any;
  multiselect: any;
  is_milestone: boolean = false;
  projectData!: Object[];
  selectedProjects: string[] = [];
  role!: string;
  baseline_start_date!: Date;
  baseline_end_date!: Date;
  indicatorsDate!: Date;
  ganttData: any[] = [];
  resourceData: any[] = [];
  resources: any[] = [];
  elem?: HTMLElement;
  dropdownlistObj?: DropDownList | any;
  holidays: any[] = [];
  workWeek: any;
  workingTime: any = [{form: 9, to: 18}]
  showBaseline: boolean = true;
  button!: Button
  addTempData: any;
  taskUuids: string[] = [];
  undoCount: number = 0;
  redoCount: number = 0;
  selectTaskId: string = '';
  allowRowDragAndDrop: boolean = true;
  isSingle: boolean = false;
  project_status!: string;
  isProjectCreating: boolean = false;
  isLoading: boolean = true;
  canEdit: boolean = false;
  projectStartDate: Date = new Date('2021-01-01');
  files: object[] = [];
  uploadingTask!: string;
  fileinfo: any[] = []
  isUploading: boolean = false
  Uploading: boolean = false

  gantt設定() {
  };



  /* ============================================== gantt 設定 ============================================== */
  checkFields: Object = {text: 'project_name', value: 'project_uuid'};
  // 宣告任務欄位
  taskSettings: object = {
    id: 'task_id',
    name: 'task_name',
    startDate: 'start_date',
    endDate: 'end_date',
    baselineStartDate: 'baseline_start_date',
    baselineEndDate: 'baseline_end_date',
    baseline_duration: 'baseline_duration',
    duration: 'duration',
    progress: 'progress',
    dependency: 'predecessor',
    resourceInfo: 'resources',
    child: 'subtasks',
    notes: 'notes',
    indicators: 'indicators',
    indicatorsName: 'indicatorsName',
    indicatorsDate: 'indicatorsDate',
    indicatorsTooltip: 'indicatorsTooltip',
    indicatorsClass: 'indicatorsClass',
    indicatorsDelete: 'indicatorsDelete',
    segments: 'segments',
    outline_number: 'outline_number',
    task_uuid: 'task_uuid',
    parent_uuid: 'parent_uuid',
    unsaved: 'unsaved'
  };
  dataSource: object[] = [
    {iconCss: "e-icons e-circle-add", text: "Add", value: "e-icons e-circle-add"},
    {iconCss: "e-icons e-circle-check", text: "Check", value: "e-icons e-circle-check"},
    {iconCss: "e-icons e-circle-close", text: "Close", value: "e-icons e-circle-close"},
    {iconCss: "e-icons e-day", text: "Day", value: "e-icons e-day"},
    {iconCss: "e-icons e-user", text: "User", value: "e-icons e-user"},
    {iconCss: "e-icons e-people", text: "People", value: "e-icons e-people"},
    {iconCss: "e-icons e-critical-path", text: "Chart", value: "e-icons e-critical-path"},
    {iconCss: "e-icons e-warning", text: "Warning", value: "e-icons e-warning"},
    {iconCss: "e-icons e-page-columns", text: "Document", value: "e-icons e-page-columns"},
    {iconCss: "e-icons e-emoji", text: "Emoji", value: "e-icons e-emoji"},
    {iconCss: "e-icons e-flags", text: "Flags", value: "e-icons e-flags"}
  ];
  editParams: object = {
    params: {
      dataSource: this.dataSource,
      fields: {text: 'text', value: 'value', iconCss: 'iconCss'},
      valueTemplate: '<div class="flex align-items-center h-full"><span class="${iconCss} mx-2"></span><span>${text}</span></div>'
    }
  }

  editSettings: GanttEditSettingsModel = {
    showDeleteConfirmDialog: false,
    allowTaskbarEditing: false,
    allowEditing: true,
    allowAdding: false,
  };
  labelSettings: object = {
    leftLabel: 'task_name',
    rightLabel: 'resources',
    taskLabel: 'progress'
  };
  selectionSettings: object = {
    type: 'Multiple'
  };
  // gantt 表格欄位
  columns: any[] = [
    {
      field: 'task_id',
      width: 50,
    },

    {
      field: 'task_name',
      width: 250
    },
    {
      field: 'notes',
      width: 100
    },
    {
      field: 'upload',
      width: 100,
      handerText: '上傳'
    },
    {
      field: 'resources',
      headerText: '負責人',
    },
    {
      field: 'duration',
      format: 'N2'
    },
    {
      field: 'start_date'
    },
    {
      field: 'end_date'
    },
    {
      field: 'baseline_start_date',
      allowEditing: false
    },
    {
      field: 'baseline_end_date',
      allowEditing: false
    },
    {
      field: 'baseline_duration',
      headerText: '基準工作天',
      valueAccessor: this.addUnit,
      allowEditing: false
    },
    {field: 'progress'},
    {field: 'predecessor'},
    {
      field: 'cost',
      headerText: '工時表',
      editType: 'numericedit',
      params: {decimals: 2, value: 5}
    },
    // {
    //   field: 'completion_percentage',
    //   headerText: '完成百分比',
    //   editType: 'numericedit',
    //   params: {decimals: 2, value: 5}
    // },
    // {
    //   field: 'task_output',
    //   headerText: '任務產出'
    // },
    {
      field: 'indicatorsName',
      headerText: '標誌名稱',
      visible: false
    },
    {
      field: 'indicatorsDate',
      headerText: '標誌日期',
      allowEditing: false,
      visible: false
    },
    {
      field: 'indicatorsTooltip',
      headerText: '標誌提示',
      visible: false
    },
    {
      field: 'indicatorsClass',
      headerText: '標誌樣式',
      editType: 'dropdownedit',
      visible: false,
      edit: {
        create: () => {
          this.elem = document.createElement('input');
          return this.elem;
        },
        read: () => {
          return this.dropdownlistObj.value;
        },
        destroy: () => {
          this.dropdownlistObj.destroy();
        },
        write: (args: Object) => {
          this.dropdownlistObj = new DropDownList({
            dataSource: [
              {iconCss: "e-icons e-circle-add", text: "Add", value: "e-icons e-circle-add"},
              {iconCss: "e-icons e-circle-check", text: "Check", value: "e-icons e-circle-check"},
              {iconCss: "e-icons e-circle-close", text: "Close", value: "e-icons e-circle-close"},
              {iconCss: "e-icons e-day", text: "Day", value: "e-icons e-day"},
              {iconCss: "e-icons e-user", text: "User", value: "e-icons e-user"},
              {iconCss: "e-icons e-people", text: "People", value: "e-icons e-people"},
              {iconCss: "e-icons e-critical-path", text: "Chart", value: "e-icons e-critical-path"},
              {iconCss: "e-icons e-warning", text: "Warning", value: "e-icons e-warning"},
              {iconCss: "e-icons e-page-columns", text: "Document", value: "e-icons e-page-columns"},
              {iconCss: "e-icons e-emoji", text: "Emoji", value: "e-icons e-emoji"},
              {iconCss: "e-icons e-flags", text: "Flags", value: "e-icons e-flags"}
            ],
            fields: {text: 'text', iconCss: 'iconCss', value: 'value'},
            value: (args as any).rowData[(args as any).column.field],
            floatLabelType: 'Auto',
          });
          this.dropdownlistObj.appendTo(this.elem);
        }
      }
    },
    {
      field: 'indicatorsDelete',
      headerText: '清除標誌',
      editType: 'dropdownedit',
      visible: false,
      edit: {
        create: () => {
          this.elem = document.createElement('button');
          return this.elem;
        },
        read: () => {
          return this.button.content;
        },
        destroy: () => {
          this.button.destroy();
        },
        write: (args: any) => {
          this.button = new Button({
            content: '清除標誌',
            isPrimary: true,
            disabled: false,
            enableRtl: false,
            enablePersistence: false,
            enableHtmlSanitizer: false,
            created: () => {
              this.button.element.onclick = () => {
                // console.log('button', args)
                let name = args.rowData.indicatorsName
                let body = {
                  task_id: args.rowData.task_id,
                  indicators: [],
                  indicatorsName: null,
                  indicatorsTooltip: null,
                  indicatorsClass: null,
                }
                this.ganttObj.updateRecordByID(body)
                this.messageServ.add({severity: 'success', summary: '成功訊息', detail: `已清除 ${name} 標誌`});
              }
            }
          });
          this.button.appendTo(this.elem);
        }
      },
    },
    {
      field: 'outline_number',
      headerText: '大綱編號',
      allowEditing: false,
    },
    {
      field: 'task_uuid',
      headerText: 'task_uuid',
      visible: false,
      allowEditing: false,
    },
  ];
  // 任務右鍵選單 隱藏 'Save', 'Cancel', 'AutoFitAll', 'AutoFit', 'SortAscending', 'SortDescending', 'Add',
  ContextMenuItems = [
    'TaskInformation',
    'DeleteTask',
    'SplitTask',
    'MergeTask',

    {
      text: '新增',
      target: '.e-content',
      id: 'Add',
      iconCss: 'e-icons e-plus',
      items: [ // 使用 items 定義子菜單
        {
          text: '子任務',
          target: '.e-content',
          id: 'Child',
          iconCss: 'e-icons e-drag-fill',
        },
        {
          text: '插入上方任務',
          target: '.e-content',
          id: 'Above',
          iconCss: 'e-icons e-insert-above'
        },
        {
          text: '插入下方任務',
          target: '.e-content',
          id: 'Below',
          iconCss: 'e-icons e-insert-below'
        },
        {
          text: '里程碑',
          target: '.e-content',
          id: 'Milestone',
          iconCss: 'e-flags e-icons'
        }

      ]
    },
    'DeleteDependency',
    'Convert',
    {
      text: 'Hide Column',
      target: '.e-gridheader',
      id: 'hidecols'
    }
  ];
  resourceFields: object = {
    id: 'resource_id',
    name: 'resource_name',
    unit: 'unit',
    group: 'resource_groups',
  };
  // 隱藏 'Add' 'Edit' 'Update' 'Cancel' 'PdfExport'
  // toolbar
  toolbar: any[] = [
    {
      text: '新增',
      tooltipText: '在底部新增任務',
      id: 'insert',
      prefixIcon: 'e-insert-above e-icons',
    },
    'Delete',
    {
      text: '移至今天',
      tooltipText: '移到今天標線',
      id: 'movetoday',
      prefixIcon: 'e-timeline-today e-icons',
    },
    'Indent',
    'Outdent',
    'ExpandAll',
    'CollapseAll',
    {
      text: '基準線',
      tooltipText: '顯示基準線',
      id: 'baseline',
      prefixIcon: 'e-timeline-day e-icons'
    },
    'CriticalPath',
    {
      text: '里程碑',
      tooltipText: '只顯示里程碑',
      id: 'milestone',
      prefixIcon: 'e-flags e-icons'
    },
    'ZoomIn',
    'ZoomOut',
    'ZoomToFit',
    'ExcelExport',
    'CsvExport',
    {
      text: '匯入',
      toollitpText: '匯入任務',
      id: 'import',
      prefixIcon: 'e-mediaclose e-icons'
    },
    {
      text: '事件',
      tooltipText: '新增專案事件',
      id: 'event',
      prefixIcon: 'e-agenda-date-range e-icons'
    },
    {
      text: '復原',
      tooltipText: '復原上一步',
      id: 'undo',
      prefixIcon: 'e-undo e-icons',
    },
    {
      text: '取消復原',
      tooltipText: '取消復原上一步',
      id: 'redo',
      prefixIcon: 'e-redo e-icons',
    },
    // {
    //   text: '測試',
    //   id: 'test',
    //   prefixIcon: 'e-code-view e-icons',
    // },
  ];
  timelineSettings: object = {
    topTier: {
      // format: 'yyyy-MM-dd',
      format: 'yyyy年MM月dd日',
      unit: 'Week',
    },
    // topTier 下方的星期
    bottomTier: {
      unit: 'Day',
      // count:4
    }
  };
  // 左側欄位顯示設定
  splitterSettings = {
    columnIndex: 4
  };
  addDialogFields: object[] = [
    {
      type: 'General',
      fields: [
        'task_name',
        'start_date',
        'end_date',
        'baseline_start_date',
        'baseline_end_date',
        'duration',
        'progress',
      ]
    },
    {type: 'Dependency'},
    {type: 'Resources'},
    {type: 'Notes'},
  ];
  editDialogFields: object[] = [
    {
      type: 'General',
      fields: [
        'task_id',
        'task_name',
        'duration',
        'progress',
        'start_date',
        'end_date',
        'baseline_start_date',
        'baseline_end_date',
      ]
    },
    {type: 'Dependency'},
    {type: 'Resources'},
    {type: 'Notes'},
    {type: 'Segments'},
    {
      type: 'TaskReport',
      fields: [],
    },
    {
      type: 'Indicators',
      headerText: '標誌',
      fields: [
        'indicatorsName',
        // 'indicatorsDate',
        'indicatorsTooltip',
        'indicatorsClass',
        'indicatorsDelete'
      ],
    },
  ];
  projects: any;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    $event.returnValue = true;
  }

  @ViewChild('gantt', {static: false}) gantt!: GanttComponent;

  初始化設定() {
  };

  /* ============================================== 初始化設定 ============================================== */
  constructor(
    private ganttServ: GanttService,
    private tokenStorage: TokenStorageService,
    private messageServ: MessageService,
    private confirmationServ: ConfirmationService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private localServ: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private fileServ: FileService
  ) {
  }

  ngOnInit(): void {
    // 從專案管理 查看單一專案
    this.id = this.route.snapshot.paramMap.get('id');


    // 從專案管理 查看多筆專案
    this.route.queryParams.subscribe(params => {
      if (params['data']) {
        this.multiselect = JSON.parse(params['data'])
        this.isEditing = false
        this.isSingle = false
        this.disableEditing()
        this.messageServ.add({severity: 'info', summary: '提示訊息', detail: '多筆專案只能查看，無法編輯'});
      }
    });
    if (!this.id && !this.multiselect && this.selectedProjects.length === 0) {
      this.isLoading = false;
    }
    if (this.id !== null && this.id !== "") {
      this.isSingle = true

    }

    // console.log('ganttObj', this.ganttObj)
    /*
    this.ganttData = new DataManager({
      url: 'https://services.syncfusion.com/angular/production/api/GanttData',
      adaptor: new WebApiAdaptor,
      crossDomain: true
    });
    */
    this.ganttData = [];
    this.getData();
    // 檢查瀏覽器是否為chrome
    const browser = window.navigator.userAgent;
  //  檢查browser裡面字串是否有chrome
    if (browser.indexOf('Chrome') > -1) {
      // console.log('browser', browser)
    } else {
      // console.log('browser', browser)
      this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '請使用 Chrome 瀏覽器'});
    }

    const todayDate = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
    const today = {day: todayDate, label: '今天'}
    this.eventMarkers.push(today)

  }


  取得資料() {
  };

  /* ============================================== 取得資料 ============================================== */
  async getData() {
    this.localServ.initializeLocalStorage();
    this.getRole();
    this.getHolidays();
    this.getWorkDays();
    await this.getResources();
    // await this.getResourcesByProjectId();
    await this.getProjects();
    await this.getProjectTasks();
    this.getTaskUuids(this.ganttData, this.taskUuids);
    this.localServ.saveData('cache', this.ganttData);
  }

  getRole() {
    this.role = this.tokenStorage.getRole();
  }

  getHolidays() {
    this.ganttServ.getHolidays().subscribe({
      next: res => {
        this.holidays = res.body.holidays
        // 每個色號建立一個 css
        this.holidays.forEach((holiday: any) => {
          const style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = `
              .e-gantt .e-gantt-chart .e-${holiday.label}-holiday {
                background-color: ${holiday.cssClass};
                opacity: .5;
                z-index: 2;
                .e-span {
                  color: #FFF;
                }
              }
      `
          this.renderer.appendChild(document.head, style);
        })
        // 把建立的 css 放進 cssClass
        this.holidays.forEach((holiday: any) => {
          holiday.cssClass = 'e-' + holiday.label + '-holiday';
        })
        // console.log('holidays', this.holidays)
      },
      error: err => {
        console.log(err)
      }
    })
  }

  getWorkDays() {
    this.ganttServ.getWorkDays().subscribe({
      next: res => {
        this.workingTime =
          [{
            from: res.body.work_days[0].workingTime[0]?.from,
            to: res.body.work_days[0].workingTime[0]?.to
          }]
        this.workWeek = res.body.work_days[0].workWeek
        // this.ganttObj.refresh();
        // console.log('workWeek', this.workWeek)
        // console.log('workingTime', this.workingTime)
      },
      error: err => {
        console.log(err)
      }
    })
  }

  async getResources() {
    try {
      const res = await this.ganttServ.getResources().toPromise();
      if (res) {
        this.resourceData = res.body.resources;
        // console.log('resourceData', this.resourceData);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getResourcesByProjectId(): Promise<any> {
    if (this.id) {
      try {
        const res = await this.ganttServ.getProjectResourcesByProjectId([this.id]).toPromise();
        this.resources = res?.body.project_resources;
      } catch (err) {
        console.log(err);
      }
    } else {
      this.resources = this.resourceData
    }
    // console.log('resources', this.resources)
  }

  async getProjects() {
    try {
      const res = await this.ganttServ.getProjects().toPromise();
      if (res) {
        this.projects = res.body.projects;

        // 根據 status 值執行不同的功能
        // 找到 baseline_start_date 和 baseline_end_date 的對象

          const baselineStartDateColumn = this.columns.find(column => column.field === 'baseline_start_date');
          const baselineEndDateColumn = this.columns.find(column => column.field === 'baseline_end_date');
          let selectProject = this.projects.find((item: any) => item.project_uuid == this.id);
          if (selectProject.status === "建檔中") {
            baselineStartDateColumn.allowEditing = true;
            baselineEndDateColumn.allowEditing = true;

        }

        this.reloadGantt();
        this.projectData = this.projects;
      }
    } catch (err) {
      console.log(err);
    }
  }

  reloadGantt() {
    // 调用 Gantt 组件的 refresh 方法重新加载组件
    if (this.gantt) {
      this.gantt.refresh();
    }
  }

  async getProjectTasks(project_uuid?: string) {
    if (project_uuid) {
      await this.getTasksByProjectIds([project_uuid]);
      this.selectedProjects = [project_uuid]
      // console.log('selectedProjects', this.selectedProjects)
    } else if (this.id) {
      this.isSingle = true;

      await this.getTasksByProjectIds([this.id]);
      this.selectedProjects = [this.id]
      // console.log('selectedProjects', this.selectedProjects)
    } else if (this.multiselect) {
      this.isSingle = false;
      this.disableEditing();
      let projectUuids = this.multiselect.map((item: { project_uuid: any; }) => item.project_uuid);
      this.selectedProjects = projectUuids;
      await this.getTasksByProjectIds(projectUuids);
    }
  }

  async getTasksByProjectIds(project_uuids: string[], milestone?: boolean) {
    try {
      const res = await this.ganttServ.getTasksByProjectIds(project_uuids, milestone).toPromise();

      if (res && res.body.tasks.length === 0) {
        this.ganttData = res.body.tasks;
        this.isLoading = false;
      } else if (res && res.body.tasks) {
        this.ganttData = res.body.tasks;
      }
      if (res && res.body.project_start_date) {
        this.projectStartDate = new Date(res.body.project_start_date)
        this.projectStartDate.setDate(this.projectStartDate.getDate() - 4);
      }
      if (project_uuids.length === 1) {
        this.project_status = res?.body.project_status
        if (res && ('is_editable' in res.body) && res.body.is_editable) {
          if (res.body.project_status) {
            if (res.body.project_status === '建檔中') {
              this.isProjectCreating = true;
              this.canEdit = true;
              this.enableEditing();
            } else if (
              res.body.project_status === '執行中' ||
              res.body.project_status === '暫停中') {
              this.canEdit = true;
              this.enableEditing();
            } else if (
              res.body.project_status === '已終止' ||
              res.body.project_status === '已完成') {
              this.disableEditing();
            }
          }
          if (res?.body.is_lock_editor) {
            this.isCance = true
            this.isEditing = false
          } else {
            this.canEdit = false
            this.isEditing = true
            this.isCance = false
            this.disableEditing()
          }
        } else {
          this.disableEditing();
        }

        if (res && res.body.event_marks) {
          this.eventMarkers = res.body.event_marks;
          this.eventMarkers.forEach((eventMarker: any) => {
            if(eventMarker.label != "今天") {
              eventMarker.day = new Date(eventMarker.day);
            }
          })
          const todayDate = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
          const today = {day: todayDate, label: '今天'}
          this.eventMarkers.push(today)
        }
      }
      this.ganttObj.toolbarModule.enableItems(['undo'], false);
      this.ganttObj.toolbarModule.enableItems(['redo'], false);
    } catch (err) {
      console.log(err)
    }
  }

  全部儲存() {
  };

  /* ============================================== 全部儲存 ============================================== */

  // 新增任務
  addTask(body: any, parent: any): Observable<any> {
    if (parent[0]) {
      body.parent_id = parent[0].parent_uuid;
    }
    body.project_uuid = this.id;
    body.notes = '';

    this.columnsEdit();

    // console.log('addTask', body)
    return this.ganttServ.addTask(body).pipe(
      map((res: any) => {
        // console.log('add body', body);
        this.taskUuids.push(res.body);
        this.messageServ.add({severity: 'success', summary: '成功訊息', detail: '已新增成功'});
        return {task_name: body.task_name, task_uuid: res.body, task_id: body.task_id, unsaved: true};
      }),
      catchError((err: any) => {
        // console.log('add body', body);
        console.log(err);
        if (err.error.detailed === "Key: 'Create.ProjectUUID' Error:Field validation for 'ProjectUUID' failed on the 'required' tag") {
          this.messageServ.add({severity: 'warn', summary: '警告訊息', detail: '請先選擇專案！'});
        } else {
          this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '新增失敗'});
        }
        throw err; // 將錯誤再次拋出，以便上層處理
      })
    );
  }

  // 點擊全部儲存
  submit() {
    let message = this.isProjectCreating ? '要將全部資料送出儲存嗎?' : '要將全部資料送出儲存嗎?  <br/><span class="">送出後新建任務的基準線將不可更改</span>';
    this.confirmationServ.confirm({
      message: message,
      header: '確認',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isLoading = true;
        this.executeTasksSequentially();
        // console.log("getTasksByProjectIds 完成");
        // this.messageServ.add({ severity: 'info', summary: '修改訊息', detail: '送出修改' });
      },
      reject: () => {
        this.messageServ.add({severity: 'warn', summary: '取消訊息', detail: '取消儲存'});
      }
    });
  }

  // 確保都刪除完畢再 update-all
  async executeTasksSequentially() {
    try {
      let deleteData = await this.checkTaskUuid(this.ganttData, this.taskUuids)
      if (deleteData.length > 0) {
        await this.removeTask(this.id, deleteData);
      }
      if (this.ganttData.length > 0) {
        this.editAllTasks(this.ganttData);
        // console.log("editAllTasks 完成");
      } else {
        this.isLoading = false;
        this.messageServ.add({severity: 'success', summary: '成功訊息', detail: '已成功儲存'});
        // console.log("editAllTasks 為空");
      }
      this.localServ.initializeLocalStorage();
      this.localServ.saveData('cache', this.ganttData);
    } catch (error) {
      console.error("發生錯誤:", error);
      this.isLoading = false;
    }
  }

  // 修改全部任務
  editAllTasks(body: any) {
    console.log('editAllTasks body', body)
    body[0].project_uuid = this.id;
    this.ganttServ.editAllTasks(body).subscribe({
      next: (res) => {
        this.isLoading = false;
        console.log('editAllTasks res', res);
        if (res) {
          this.messageServ.add({severity: 'success', summary: '成功訊息', detail: '已成功儲存'});
          this.isCance = false
          this.getTasksByProjectIds([this.id]);
        } else {
          this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '儲存失敗'});
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err);
        this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '儲存失敗'});
      },
    });
  }

  // 確保陣列前一筆刪除完畢才執行下一筆刪除，以避免子任務未刪除完畢就刪除父任務報錯
  async removeTask(project_uuid: string, task_uuids: string[]) {
    try {
      let body = {
        project_uuid: project_uuid,
        tasks: task_uuids
      }
      const res = await this.ganttServ.removeTask(body).toPromise();
      // console.log(res);
      // this.messageServ.add({severity: 'success', summary: '成功訊息', detail: '已成功刪除'});
    } catch (error: any) {
      console.log(error);
      switch (error.status) {
        case 404:
          // this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '已無此任務，請重新整理再嘗試!'});
          break;
        default:
          // this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '刪除失敗'});
          break;
      }
    }
  }

  其他function() {
  };

  /* ============================================== 其他function ============================================== */
  submitProjects() {
    this.ganttData = [];
    // 無選擇專案時
    if (this.selectedProjects.length === 0) {
      this.isSingle = false;
      this.isEditing = false
      this.ganttData = [];
      // 只選擇一個專案時
    } else if (this.selectedProjects.length === 1) {
      this.isLoading = true;
      this.isSingle = true;
      this.isEditing = true;
      this.id = this.selectedProjects[0]

      this.getTasksByProjectIds([this.id])
      this.disableEditing()
      // this.getResourcesByProjectId()
      // 選擇多個專案時
    } else if (this.selectedProjects.length > 1) {
      this.isLoading = true;
      this.isSingle = false;
      this.disableEditing();
      this.messageServ.add({severity: 'info', summary: '提示訊息', detail: '多筆專案只能查看，無法編輯'});
      this.getTasksByProjectIds(this.selectedProjects)
      // this.resources = this.resourceData
    }
    // console.log(this.selectedProjects)
    // this.setProjectsByProjectsId(this.selectedProjects);
  }

  addUnit(field: string, data: {
    baseline_duration: number,
    duration: number
    baseline_start_date: Date,
    baseline_end_date: Date
  }, columns: Object): any {
    if ((data.duration !== null) || (data.baseline_end_date !== null && data.baseline_start_date !== null)) {
      if (data.baseline_duration > 0) {
        return data.baseline_duration + '天';
      } else {
        return 0 + '天';
      }
    }
    return null;
  };

  // 計算工作天數
  countDuration(start_date: any, end_date: any) {
    return this.ganttObj.dataOperation.getDuration(new Date(start_date), new Date(end_date), 'day', true, false);
  }

  countStartDate(end_date: any, duration: number) {
    let start_date = new Date(end_date);
    while (duration > 0) {
      // Skip weekends & holidays
      if (start_date.getDay() !== 0 && start_date.getDay() !== 6 && !this.isHoliday(start_date)) {
        duration--;
      }
      if (duration > 0) {
        start_date.setDate(start_date.getDate() - 1);
      }
    }
    return start_date;
  }

  countEndDate(start_date: Date, duration: number) {
    console.log('start_date', start_date, 'duration', duration)
    let end_date = new Date(start_date);
    while (duration > 0) {
      // Skip weekends & holidays
      if (end_date.getDay() !== 0 && end_date.getDay() !== 6 && !this.isHoliday(end_date)) {
        duration--;
      }
      if (duration > 0) {
        end_date.setDate(end_date.getDate() + 1);
      }
    }
    return end_date;
  }

  updatePreviousData() {
    this.undoCount++;
    if (this.undoCount > 0) {
      this.ganttObj.toolbarModule.enableItems(['undo'], true);
    }
    if (this.undoCount > 10) {
      this.undoCount = 1;
    }
    let previousData = this.localServ.getData('cache');
    this.localServ.saveData(`undoData${this.undoCount}`, previousData);
    this.localServ.saveData('cache', this.ganttData);
  }

  isDataNull(data: string) {
    for (let i = 1; i < 11; i++) {
      let checkData = this.localServ.getData(`${data}${i}`)
      if (checkData) {
        return false;
      }
    }
    return true;
  }

  // 取得 task_uuid 陣列
  getTaskUuids(data: any, taskUuids: string[]) {
    data.forEach((item: any) => {
      taskUuids.push(item.task_uuid)
      if (item.subtasks) {
        this.getTaskUuids(item.subtasks, taskUuids)
      }
    })
  }

  checkTaskUuid(data: any, initData: string[]) {
    let taskUuids: string[] = []
    this.getTaskUuids(data, taskUuids)
    // console.log('taskUuids', taskUuids)
    // console.log('deleteData', initData.filter(item => !taskUuids.includes(item)))
    return initData.filter(item => !taskUuids.includes(item));
  }

  // 開關baseline
  baseLineSwitch() {
    this.showBaseline = !this.showBaseline;
  }

  // 判斷是否為子任務
  rowSelecting(args: any) {
    // console.log('rowSelecting', args)
    this.selectTaskId = args.data.task_id
    if (this.canEdit) {
      if ((this.selectedProjects.length < 0 || !this.multiselect) && (args.data.taskData && args.data.taskData.is_editable)) {
        this.ganttObj.editSettings.allowEditing = true;
        this.ganttObj.editSettings.allowTaskbarEditing = true;
        if (!args.data.taskData.subtasks || args.data.taskData.subtasks.length === 0) {
          this.ganttObj.editSettings.allowDeleting = true;
        }
      } else {
        this.ganttObj.editSettings.allowDeleting = false;
        this.ganttObj.editSettings.allowEditing = false;
        this.ganttObj.editSettings.allowTaskbarEditing = false;
      }
    }
  }

  enableEditing() {
    this.allowRowDragAndDrop = true;
    this.ganttObj.editSettings.allowAdding = true;
    this.ganttObj.editSettings.allowDeleting = true;
    this.ganttObj.editSettings.allowEditing = true;
    this.ganttObj.editSettings.allowTaskbarEditing = true;
    this.ganttObj.toolbarModule.enableItems(['insert'], true);
    this.ganttObj.toolbarModule.enableItems(['event'], true);
    this.ganttObj.toolbarModule.enableItems(['import'], true);
    this.isUploading = true
  }



  // 設定為不可編輯
  disableEditing() {
    this.allowRowDragAndDrop = false;
    this.ganttObj.editSettings.allowAdding = false;
    this.ganttObj.editSettings.allowDeleting = false;
    this.ganttObj.editSettings.allowEditing = false;
    // this.ganttObj.editSettings.allowTaskbarEditing = false;
    this.ganttObj.toolbarModule.enableItems(['insert'], false);
    this.ganttObj.toolbarModule.enableItems(['event'], false);
    this.ganttObj.toolbarModule.enableItems(['import'], false);
    this.isUploading = false
  }


  // 判斷是否為假期
  isHoliday(date: Date): boolean {
    const dateSetHours = new Date(date.setHours(0, 0, 0, 0));
    const dateString = dateSetHours.toISOString().slice(0, 10);
    return this.holidays.some(holiday => dateString >= holiday.from.slice(0, 10) && dateString <= holiday.to.slice(0, 10));
  }

  // 取得專案狀態顏色
  getStatusColor(status: string) {
    switch (status) {
      case '建檔中':
        return 'p-button-sm p-button-outlined p-button-secondary';
      case '執行中':
        return 'p-button-sm  p-button-outlined p-button-info';
      case '暫停中':
        return 'p-button-sm  p-button-outlined p-button-warning';
      case '已終止':
        return 'p-button-sm  p-button-outlined p-button-danger';
      case '已完成':
        return 'p-button-sm  p-button-outlined p-button-success';
      default:
        return 'p-button-sm  p-button-outlined p-button-secondary';
    }
  }

  // 專案事件 event
  eventMarkers: object[] = [];
  // pageSettings: object = {pageCount: 8};
  eventEditOptions: GridEditSettingsModel = {
    newRowPosition: 'Bottom',
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true
  };
  eventToolbarItems: ToolbarItems[] = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
  formatoptions: object = {type: 'date', format: 'yyyy-MM-dd'};
  orderidrules: object = {required: true};

  editEvents(args: any) {
    // console.log('editEvents', args)
    if (args.requestType === 'save') {
      if (args.data.id) {
        // 執行 patch
        let id = args.data.id
        let data = {
          project_uuid: this.id,
          label: args.data.label,
          day: args.data.day
        }
        this.editEvent(id, data)
      } else {
        // 執行 post
        let data = {
          project_uuid: this.id,
          label: args.data.label,
          day: args.data.day
        }
        this.addEvent(data)
      }
    }
    if (args.requestType === 'delete') {
      let id = args.data[0].id
      this.removeEvent(id)
    }
  }

  addEvent(data: any) {
    this.ganttServ.addEvent(data).subscribe({
      next: (res) => {
        // console.log('addEvent', data)
        this.messageServ.add({severity: 'success', summary: '新增成功', detail: `已新增 ${data.label} 事件`});
        this.ganttObj.eventMarkers = [...this.ganttObj.eventMarkers, data];
      },
      error: (err) => {
        // console.log('addEvent', err)
        this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '新增失敗'});
      }
    })
  }

  editEvent(id: string, data: any) {
    this.ganttServ.editEvent(id, data).subscribe({
      next: (res) => {
        // console.log('editEvent', data)
        this.messageServ.add({severity: 'success', summary: '儲存成功', detail: '已修改事件'});
        this.ganttObj.eventMarkers = [...this.ganttObj.eventMarkers, data];
      },
      error: (err) => {
        console.log('editEvent', err)
        this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '儲存失敗'});
      }
    })
  }

  removeEvent(id: string) {
    this.ganttServ.removeEvent(id).subscribe({
      next: (res) => {
        // console.log('removeEvent', id)
        this.messageServ.add({severity: 'success', summary: '成功刪除', detail: '已刪除事件'});
        this.ganttObj.eventMarkers = this.ganttObj.eventMarkers.filter((eventMarker: any) => eventMarker.id !== id);
      },
      error: (err) => {
        // console.log('removeEvent', id)
        this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '刪除失敗'});
      }
    })
  }

  gantt事件$event() {
  };

  /* ============================================== gantt 事件$event ============================================== */
  actionBegin(args: any): void {
    if (
      args.requestType === 'beforeOpenEditDialog' ||
      args.requestType === 'beforeOpenAddDialog'
    ) {
      // console.log("actionBegin", args);
      /*
      let column: any = (this.ganttObj.columnByField as any)["CustomField"];
      this.divElement = this.ganttObj.createElement("div", {className: "e-edit-form-column"});
      let inputElement: any;
      inputElement = this.ganttObj.createElement("input", {
        attrs: {
          type: "text",
          id: this.ganttObj.controlId + "" + column.field,
          name: column.field,
          title: column.field
        }
      });
      this.divElement.appendChild(inputElement);
      let input = {
        enabled: true,
        floatLabelType: "Auto",
        placeholder: "CustomField",
        value: args.rowData.indicators
      };
      let inputObj = new (this as any).inputs[column.editType](input);
      inputObj.appendTo(inputElement);
      let column = args.Resources.columns[3];
      args.Resources.columns.push(column);
      */
      (document.getElementById('gantt') as any)['ej2_instances'][0][
        'columnByField'
        ].indicatorsName.visible = true;
      (document.getElementById('gantt') as any)['ej2_instances'][0][
        'columnByField'
        ].indicatorsTooltip.visible = true;
      (document.getElementById('gantt') as any)['ej2_instances'][0][
        'columnByField'
        ].indicatorsClass.visible = true;
      (document.getElementById('gantt') as any)['ej2_instances'][0][
        'columnByField'
        ].indicatorsDelete.visible = true;
      args.Resources.columns.push({field: 'role', headerText: '專案角色'});
    }
  }

  actionComplete(args: any): void {
    // console.log('actionComplete', args)
    // 判斷是否 loading 結束
    if (args.requestType === 'refresh' && args.rows && args.rows.length > 0) {
      this.isLoading = false;
    }
    /*if (
      args.requestType === 'openEditDialog' ||
      args.requestType === 'openAddDialog'
    ) {
      (document.getElementById('gantt') as any)['ej2_instances'][0][
        'columnByField'
        ].indicatorsName.visible = false;
      (document.getElementById('gantt') as any)['ej2_instances'][0][
        'columnByField'
        ].indicatorsTooltip.visible = false;
      (document.getElementById('gantt') as any)['ej2_instances'][0][
        'columnByField'
        ].indicatorsClass.visible = false;
      (document.getElementById('gantt') as any)['ej2_instances'][0][
        'columnByField'
        ].indicatorsDelete.visible = false;
    }*/

    if (args.requestType === 'add') {
      console.log('add', args)
      let parentData = args.modifiedTaskData;
      this.addTempData = args.newTaskData;
      this.addTask(this.addTempData, parentData).subscribe((result: any) => {
        // 在這裡處理 API 回應的結果
        // 調用 addRecord 或其他操作
        this.ganttObj.updateRecordByID(result);
        console.log(result)
        // console.log('getRecordByID', this.ganttObj.getRecordByID(result.task_id))
      });
      this.updatePreviousData();
    }

    if (args.requestType === 'delete') {
      this.updatePreviousData();
    }

    if(args === '移至今天') {
      const todayDate = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
      this.ganttObj.scrollToDate(todayDate);
    }

    // 儲存上一步
    if (args.requestType === 'rowDropped' || args.requestType === 'indented' || args.requestType === "outdented" ||
      (args.requestType === 'save' && (args.action === 'CellEditing' || args.action === 'DialogEditing'))) {
      this.updatePreviousData();
    }

    if (args.requestType === 'save' && (args.action === 'CellEditing' || args.action === 'DialogEditing')) {
      // 把值帶入 indicators 欄位
      if (args.data.indicatorsName) {
        let body = {
          task_id: args.data.task_id,
          indicators: [
            {
              date: args.data.baseline_end_date && args.data.end_date ?
                (args.data.baseline_end_date > args.data.end_date ? args.data.baseline_end_date : args.data.end_date) :
                (args.data.end_date ? args.data.end_date : (args.data.baseline_end_date ? args.data.baseline_end_date : this.projectStartDate)),
              name: args.data.indicatorsName ? args.data.indicatorsName : null,
              tooltip: args.data.indicatorsTooltip ? args.data.indicatorsTooltip : null,
              iconClass: args.data.indicatorsClass,
            }
          ]
        }
        this.ganttObj.updateRecordByID(body)
      }
    }

    if (this.isProjectCreating || (args && args.data && args.data.taskData && args.data.taskData.unsaved)) {
      // 修改 parentItem baseline
      if (args.requestType === 'save' && args.action !== 'DrawConnectorLine') {
        if (args.data.parentItem) {
          let parentTask: any = this.ganttObj.getRecordByID(args.data.parentItem.taskId);
          let body = {
            task_id: parentTask.task_id,
            baseline_start_date: parentTask.start_date,
            baseline_end_date: parentTask.end_date,
            baseline_duration: parentTask.duration
          }
          this.ganttObj.updateRecordByID(body)
        }
      } else if (args.requestType === 'indented' || args.requestType === 'outdented' ||
        args.requestType === 'rowDropped') {
        if (args.modifiedRecords.length > 0) {
          args.modifiedRecords.forEach((item: any) => {
            let body = {
              task_id: item.task_id,
              baseline_start_date: item.start_date,
              baseline_end_date: item.end_date,
              baseline_duration: item.duration
            }
            this.ganttObj.updateRecordByID(body)
          })
        }
      }
    }

    //region 開始、結束、工作日
    if ((args.column && (args.column.field === "start_date" || args.column.field === "end_date" ||
      args.column.field === "duration")) || (args.requestType === 'save' && args.action === 'DrawConnectorLine')) {
      const taskData: any = this.ganttObj.getRecordByID(args.data.task_id)
      let body = {
        task_id: taskData.task_id,
        baseline_start_date: taskData.start_date,
        baseline_end_date: taskData.end_date,
        baseline_duration: taskData.duration
      }
      this.ganttObj.updateRecordByID(body)
    }
    //endregion
  }

  contextMenuClick(args: any) {
    // 新增子任務
    if (args.item.properties.text === '子任務' || args.item.properties.text === '插入下方任務' || args.item.properties.text === '插入上方任務') {
      let data = {
        task_name: '未命名任務',
        unsaved: true,
        is_editable: true
      };
      if (args.item.properties.text === '子任務') {
        if (this.id) {
          this.ganttObj.addRecord(data, 'Child');
          this.gantt.collapseAllParentTasks = true
        } else {
          this.messageServ.add({severity: 'warn', summary: '警告訊息', detail: '請先選擇專案！'});
        }
      }
      if (args.item.properties.text === '插入下方任務') {
        if (this.id) {
          this.ganttObj.addRecord(data, 'Below')
        } else {
          this.messageServ.add({severity: 'warn', summary: '警告訊息', detail: '請先選擇專案！'});
        }
      }
      if (args.item.properties.text === '插入上方任務') {
        if (this.id) {
          this.ganttObj.addRecord(data, 'Above');
        } else {
          this.messageServ.add({severity: 'warn', summary: '警告訊息', detail: '請先選擇專案！'});
        }
      }
    }
    if (args.item.properties.text === '里程碑') {
      let data = {
        task_name: '未命名里程碑',
        unsaved: true,
        duration: 0,
        start_date: new Date(),
        end_date: new Date(),
        baseline_start_date: new Date(),
        baseline_end_date: new Date(),
        baseline_duration: 0,
        is_editable: true
      };
      if (this.id) {
        this.ganttObj.addRecord(data, 'Below')
      } else {
        this.messageServ.add({severity: 'warn', summary: '警告訊息', detail: '請先選擇專案！'});
      }

    }
  }

  queryTaskbarInfo(args: any): void {
    // console.log('queryTaskbarInfo', args)
    // 可編輯 bg (taskbar部分)
    if (this.canEdit) {
      if (!args.data.taskData.is_editable) {
        args.rowElement.style.backgroundColor = 'rgba(130,130,130,0.25)';
      }
    } else {
      args.rowElement.style.backgroundColor = 'rgba(130,130,130,0.25)';
    }
    // 判斷 baseline 顏色
    if (args.data.baseline_end_date > args.data.end_date) {
      args.baselineColor = '#3BCE6C';
    } else if (new Date(args.data.baseline_end_date).getTime() === new Date(args.data.end_date).getTime()) {
      args.baselineColor = '#5BA4C6'; // 原本顏色 #68BCE3
    } else if (args.data.baseline_end_date < args.data.end_date) {
      args.baselineColor = '#FF6B6B';
    }
    // 若有 indicators，則隱藏右邊的 label
    if (args.data.indicators && args.data.indicators[0] && args.data.indicators[0].name) {
      args.rowElement.querySelector('.e-right-label-container').style.display = 'none';
    }
  }

  queryCellInfo(args: any): void {
    // console.log('queryCellInfo', args)
    // 可編輯 bg (column部分)
    if (args.column != null && args.column.field == "notes") {
      if (args.data.notes != "<p><br></p>" && args.data.notes != "" && args.data.notes != null) {
        args.cell.innerHTML = '<div class="e-ganttnotes-info"><span class="e-icons e-notes-info e-notes"></span></div>';
      } else {
        args.cell.innerHTML = '';
      }
    }

    if (this.canEdit) {
      if (!args.data.taskData.is_editable) {
        args.cell.style.backgroundColor = 'rgba(130,130,130,0.25)';
      }
    } else {
      args.cell.style.backgroundColor = 'rgba(130,130,130,0.25)';
    }
  }

  toolbarClick(args: ClickEventArgs): void {
    // 測試用
    if (args.item.id === 'test') {
    }
    // 基準線顯示
    if (args.item.id === 'baseline') {
      this.baseLineSwitch();
    }
    if(args.item.id === 'movetoday') {
      console.log("測試")
      const todayDate = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
      this.ganttObj.scrollToDate(todayDate);
    }
    // 文件匯出
    if (args.item.id?.endsWith("excelexport")) {
      this.ganttObj.excelExport();
    } else if (args.item.id?.endsWith("csvexport")) {
      this.ganttObj.csvExport();
    } else if (args.item.id?.endsWith("pdfexport")) {
      this.ganttObj.pdfExport();
    }
    // 匯入
    if (args.item.id?.endsWith("import")) {
      this.isUpload = true
    }

    // 匯入
    if(args.item.id?.endsWith("import")) {
      this.isUpload = true
    }

    // 新增任務
    if (args.item.id === 'insert') {
      let data = {
        task_name: '未命名任務',
        unsaved: true,
        is_editable: true
      };
      if (this.id) {
        this.ganttObj.addRecord(data, 'Bottom');
        this.gantt.collapseAllParentTasks = true
      } else {
        this.messageServ.add({severity: 'warn', summary: '警告訊息', detail: '請先選擇專案！'});
      }
    }

    // 里程碑
    if (args.item.id === 'milestone') {
      this.is_milestone = !this.is_milestone
      if (this.selectedProjects.length === 1) {
        this.getTasksByProjectIds([this.selectedProjects[0]], this.is_milestone)
      } else if (this.selectedProjects.length > 1) {
        this.getTasksByProjectIds(this.selectedProjects, this.is_milestone)
      }
    }

    // 事件
    if (args.item.id === 'event') {
      this.dialogObj.show()
    }

    // 復原
    if (args.item.id === 'undo') {
      this.redoCount++;
      this.ganttObj.toolbarModule.enableItems(['redo'], true);
      if (this.redoCount > 10) {
        this.redoCount = 1;
      }
      let currentData = this.localServ.getData('cache');
      this.localServ.saveData(`redoData${this.redoCount}`, currentData);
      this.ganttData = this.localServ.getData(`undoData${this.undoCount}`);
      this.localServ.saveData('cache', this.ganttData)
      this.localServ.removeData(`undoData${this.undoCount}`);
      this.undoCount--;
      if (this.undoCount === 0 && !this.isDataNull('undoData')) {
        this.undoCount = 10;
      }
      if (this.isDataNull('undoData')) {
        this.ganttObj.toolbarModule.enableItems(['undo'], false);
      }
    }

    // 取消復原
    if (args.item.id === 'redo') {
      this.undoCount++;
      this.ganttObj.toolbarModule.enableItems(['undo'], true);
      if (this.undoCount > 10) {
        this.undoCount = 1;
      }
      let currentData = this.localServ.getData('cache');
      this.localServ.saveData(`undoData${this.undoCount}`, currentData);
      this.ganttData = this.localServ.getData(`redoData${this.redoCount}`);
      this.localServ.saveData('cache', this.ganttData)
      this.localServ.removeData(`redoData${this.redoCount}`);
      this.redoCount--;
      if (this.redoCount === 0 && !this.isDataNull('redoData')) {
        this.redoCount = 10;
      }
      if (this.isDataNull('redoData')) {
        this.ganttObj.toolbarModule.enableItems(['redo'], false);
      }
    }
  }

  columnsEdit() {
    this.projects.map((item: any) => {
      // 找到 baseline_start_date 和 baseline_end_date 的對象
      const baselineStartDateColumn = this.columns.find(column => column.field === 'baseline_start_date');
      const baselineEndDateColumn = this.columns.find(column => column.field === 'baseline_end_date');

      baselineStartDateColumn.allowEditing = true;
      baselineEndDateColumn.allowEditing = true;
      this.reloadGantt();
    });
  }

  checkEditer() {
    this.ganttServ.checkEditer(this.id).subscribe({
      next: (res) => {
        // 提示可編輯限時一小時
        if (res.body.is_editable) {

          this.isEditing = false
          this.canEdit = true
          this.isCance = true
          this.enableEditing();

          this.messageServ.add({severity: 'info', summary: '提示訊息', detail: '您有一小時的編輯時間', life: 3000});
          this.getProjectTasks()


        } else {
          this.messageServ.add({
            severity: 'warn',
            summary: '警告訊息',
            detail: `有用戶${res.body.current_editor}編輯中，請稍後再試`,
            life: 3000
          });
          this.disableEditing();
          this.canEdit = false;
          this.isCance = false;
          this.isEditing = true
        }
      },
      error: (err) => {

        this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '編輯狀態獲取失敗', life: 3000});
      }

    })
  }

  canceEditer() {
    this.ganttServ.canceEditer(this.id).subscribe({
      next: (res) => {

        this.messageServ.add({severity: 'success', summary: '成功訊息', detail: '已取消編輯', life: 3000});
        this.disableEditing()
        this.canEdit = false;
        this.isEditing = true
        this.isCance = false
      },
      error: (err) => {
        this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '取消編輯失敗', life: 3000});
      }
    })
  }

  getTaskFiles(id: string) {
    this.fileServ.getTaskFiles(id).subscribe({
      next: (res) => {
        this.files = res.body.s3_files;
        console.log(this.files)
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  formatBytes(bytes: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

    let index = 0;
    let size = bytes;

    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }

    return size.toFixed(2) + ' ' + units[index];
  }


  showUploadDiaolg(data: any) {
    console.log(data)
    data.taskData.is_editable ? this.canEdit = true : this.canEdit = false;
    this.uploadingTask = data.taskData.task_uuid;
    this.uploadedFiles = [];
    this.getTaskFiles(data.taskData.task_uuid);
    this.uploadDialogObj.show();
    // console.log('files', this.files)
  }

  // 打開下載檔案連結
  openUrl(url: string) {
    console.log('url', url)
    window.open(url, '_blank');
  }

  downloadFile(id: string) {
    this.fileServ.downloadFile(id).subscribe({
      next: (res) => {
        console.log(res)
        window.open(res.body, '_blank');
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  uploadedFiles: any;

 async uploadHandler(event: FileUploadHandlerEvent) {
  this.Uploading = true
    let fileObj: FileObj
     let fileObjs: FileObj[] = []


      const client = new S3Client({

         region: environment.region,
         credentials: {
            accessKeyId: environment.accessKeyId,
            secretAccessKey: environment.secretAccessKey
         }
         });

      for (let file of event.files) {
        const fn = file.name.split('.')
        const uuid = this.generateUUID();

        const params = {
          Bucket: 'file.pm.likbox.com',
          Key: uuid + "." + fn[fn.length - 1],
          Body: file,
          ContentType: file.type,
        };
        const command = new PutObjectCommand(params);

     await   client.send(command).then((data) => {
          //  fileObj初始化
          fileObj = {
            file_name: '',
            file_path: '',
            file_size: 0,
          }


          const uuid = this.generateUUID();

          fileObj.file_name = file.name
          fileObj.file_path = params.Bucket + "/" + params.Key
          fileObj.file_size = file.size
          fileObjs.push(fileObj)

        }).catch((error) => {
          console.error(error);
          // 上傳失敗message
          this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '上傳失敗'});
        });
        }
        let fileIn: FileObjInfo = {
          source_uuid: this.uploadingTask,
          files: fileObjs
        }
      this.fileServ.putUpload(fileIn).subscribe({
        next: (res: any) => {
          console.log(res)
          this.Uploading = false
          this.messageServ.add({severity: 'success', summary: '成功訊息', detail: '已上傳成功'});

          this.uploadedFiles = [];
          this.fileUpload.clear();
          this.uploadDialogObj.hide();
          this.getTasksByProjectIds([this.id]);
        },
        error: (err: any) => {
          console.log(err);
          this.Uploading = false
          this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '上傳失敗'});
          this.fileUpload.clear();
          this.uploadDialogObj.hide();
          this.getTasksByProjectIds([this.id]);
        }
      })



      }






  converBase64ToBlob(base64: any, fileName: string, id: string) {

    const base64String: any = base64.split(",")

    let fileData: FileInfo = {
      base64: base64String[1],
      file_name: fileName,
      source_uuid: id,
    }
    // fileData push this.fileInfo
    this.fileinfo.push(fileData)


  }

  uploadBase64File(body: any) {
    console.log(body)

    this.fileServ.uploadFiles(body).subscribe({
      next: res => {
        console.log(res)
        this.messageServ.add({severity: 'success', summary: '成功訊息', detail: '已上傳成功'});
        this.uploadDialogObj.hide();
        this.fileinfo = [];
        this.fileUpload.clear();
        this.getTasksByProjectIds([this.id]);
      },
      error: err => {
        console.log(err)
        this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '上傳失敗'});
        this.uploadDialogObj.hide();
        this.fileinfo = []
        this.fileUpload.clear();
        this.getTasksByProjectIds([this.id]);
      }
    })
  }






  onUpload(event: any) {
    console.log("上傳")
    for (let file of event.files) {
      this.convertaFileToBase64(file)
    }
  }

  convertaFileToBase64(file: File) {
    const reader = new FileReader();
    // reader.readAsText(file, 'utf-8');
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.uploadBase64ToBackend(reader.result, file.name)
    }
  }

  uploadBase64ToBackend(base64String: any, fileName: string) {
    const str = base64String.split(",")
    const body = {
      base64: str[1],
      file_type: 1,
      project_uuid: this.id
    }
    this.ganttServ.importTask(body).subscribe({
      next: (res) =>
 {
        this.messageServ.add({severity: 'success', summary: '成功訊息', detail: '匯入成功'});
        // window.location.reload()
        this.getTasksByProjectIds([this.id]);
        this.isUpload = false

      },
      error: (err) => {
        console.log(err);
        this.messageServ.add({severity: 'error', summary: '錯誤訊息', detail: '匯入失敗'});

      }
    })
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  beforeTooltipRender(args: any) {
    if (args.content == "" && args.args.target.classList.contains('e-icons') && args.args.target.classList.contains('e-notes-info')) {
      args.cancel = true;
    }
  }




}
